.ant-layout-sider-children {
  .ant-menu-item {
    height: 50px !important;
    padding-left: 5px !important;
    border-radius: 0;
  }

  .ant-menu-item-selected {
    background-color: #f58a07 !important;
  }
  .ant-menu-submenu-title {
    padding-left: 0 !important;
    margin:4px
  }
  .ant-menu-item-only-child {
    padding-left: 40px !important;
  }

  ul {
    padding: 0px !important;

    li {
      margin: 0px !important;
      width: 100% !important;
    }
  }
}