.top-header-app {
  background-color: #00315780;
  padding-left: 0px;
  padding-right: 0px;
  height: auto;
}

.top-header-app div {
  text-align: center;
}

.top-header-app div img {
  height: 50px;
}

.side-bar-menu {
  background-color: #003157;
}

.side-bar-menu div {
  background-color: #003157;
}

.side-bar-menu div ul {
  background-color: #003157;
}

.side-menu-main li {
  border: none;
  border-radius: 0;
  border-bottom: 0;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 100px;
}
